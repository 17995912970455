/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react'
import AxiosInstance from '../../../../utils/AxioxInstance'
import { ToastContainer,toast } from 'react-toastify'
function Index() {
  const ref=useRef()
  const [Form,setForm]=useState({
    courseid:"",
    url:""
  })
  const Add=(e)=>{
    e.preventDefault()
      try {
        AxiosInstance.post("/course/update",Form)
        .then((res)=>{
         toast.success("Course URL Added")

          ref.current.reset()
        })
      } catch (error) {
        toast.error("something wrong contact admin");
      }
  }
  return (
    <div>
      <ToastContainer />
      <h1 className="text-center">Upload Courses</h1>
      <div className="w-[40%] m-auto pt-6">
        <form onSubmit={(e) => Add(e)} ref={ref}>
          <label className="p-10">Select Course</label>
          <select
            className="p-2 px-7 rounded-md bg-orange-400"
            required
            onChange={(e) =>
              setForm((prev) => ({ ...prev, courseid: e.target.value }))
            }
          >
            <option value="" defaultChecked>
              --Select Category--
            </option>
            <option value={"html@sk"}>HTML</option>
            <option value={"css@sk"} disabled>
              CSS
            </option>
            <option value={"js@sk"} disabled>
              JavaScript
            </option>
            <option value={"react@sk"} disabled>
              ReactJS
            </option>
          </select>
          <br />
          <br />
          <label className="pl-7">Enter Video URL</label>
          <input
            type="url"
            className="ml-10"
            required
            onChange={(e) =>
              setForm((prev) => ({ ...prev, url: e.target.value }))
            }
          />
          <br />
          <button
            className="ml-[180px] mt-20 border-2 rounded-lg px-6 py-4 hover:bg-transparent bg-lime-500"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Index