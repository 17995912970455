/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import AxiosInstance from '../../../../utils/AxioxInstance'
import { db, storage } from "../../../../firebase"
import Loader from "../../../../components/loader"
import { ToastContainer,toast } from 'react-toastify'
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
function Index() {
    const [Data,setData]=useState([])
    const [file,setfile]=useState([])
    const GetData=async()=>{
       await AxiosInstance.get("/admin/users").then((res) => {
          console.log(res?.data);
          setData(res?.data);
        });
       
        
    }
    const Accept=async(e)=>{
      await AxiosInstance.put("/client/verify", {
        mailid: e.mailid,
        courseid: e.Courses[0]?.courseid,
        verification: true,
      })
        .then((res) => {
          toast.success("Course Payment Confirmed")
        })
        .catch((error) => {
          console.log(error);
        });
        GetData();
    }
    const Decline =async (e) => {
      await AxiosInstance.put("/client/verify", {
        mailid: e.mailid,
        courseid: e.Courses[0]?.courseid,
        verification: false,
      })
        .then((res) => {
          toast.error("Course Payment Declined");
        })
        .catch((error) => {
          console.log(error);
        });
      GetData();
    };
    useEffect(()=>{
        GetData()
    },[])
    useEffect(()=>{
       Data.forEach((element) => {
         element.Courses.forEach((element) => {
           var imageRef = storageRef(storage, element.path);

           console.log(imageRef);
           getDownloadURL(imageRef).then((url) => {
             console.log(url);
             setfile((prev) => [...prev, url])
           });
         });
       });
    },[Data])
  return (
    <div>
      <ToastContainer/>
      <div>
        <h1 className="text-center">Details About Users</h1>
        <table className="table-auto border-separate md:border-spacing-2 border-2 rounded-md border-blue-600 ml-auto mr-auto">
          <thead className="bg-orange-400 border-2">
            <tr>
              <th className="border border-slate-300 md:p-3 rounded-sm">
                S.NO
              </th>
              <th className="border border-slate-300 md:p-3 rounded-sm">
                Name
              </th>
              <th className="border border-slate-300 md:p-3 rounded-sm max-[600px]:hidden  ">
                Email
              </th>
              <th className="border border-slate-300 md:p-3 rounded-sm max-[600px]:hidden ">
                Phone No
              </th>
              <th className="border border-slate-300 md:p-3 rounded-sm">
                Courses
              </th>
              <th className="border border-slate-300 md:p-3 rounded-sm">
                Verify
              </th>
            </tr>
          </thead>
          <tbody>
            {Data?.map((e, index) => {
              return (
                <tr key={index}>
                  <td className="border border-green-300 rounded-sm md:p-3">
                    {index + 1}
                  </td>
                  <td className="border border-green-300 rounded-sm md:p-3">
                    {e.name}
                  </td>
                  <td className="border border-green-300 rounded-sm md:p-3 max-[600px]:hidden ">
                    {e.mailid}
                  </td>
                  <td className="border border-green-300 rounded-sm md:p-3 max-[600px]:hidden ">
                    {e.PH}
                  </td>
                  <td className="border border-green-300 rounded-sm md:p-3">
                    {e.Courses?.map((e) => {
                      return (
                        <>
                          <p>Course Id:{e.courseid}</p>
                          <p>
                            Payment Verification:{e.verification ? "Yes" : "No"}
                          </p>
                          <br />
                        </>
                      );
                    })}
                  </td>
                  <td className="border border-green-300 rounded-sm md:p-3">
                    {e.Courses?.map((el) => {
                      
                      return (
                        <>
                          <a
                          key={index}
                            href={file[index]}
                            target="_blank"
                            className="underline text-[blue] hover:text-[green]"
                          >
                            View Payment
                          </a>
                          <div key={index} className="flex">
                            <button onClick={el=>Accept(e)} className="border-2 rounded-md p-2 bg-green-800">
                              Accept
                            </button>
                            <button onClick={el=>Decline(e)} className="border-2 rounded-md p-2 m-1 bg-orange-800">
                              Decline
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Index