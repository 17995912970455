/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
const initialState={
    name:"",
    mailid:"",
    loading:false
}
const UsersSlice=createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        Login:(state,action)=>{
            state.name = action.payload.name;
            state.mailid=action.payload.email
            state.loading = action.payload.loading;
            return {"message":"Login Success"}
        },
        Logout:(state,action)=>{
            state.mailid=""
            state.loading=false
            state.name=""
            return { message: "Logout Success" };
        }
    }
})
export const {Login,Logout}=UsersSlice.actions
export default UsersSlice.reducer