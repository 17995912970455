/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Route } from 'react-router-dom'
import AdminDashboard from '../Dashboard/index'
import { Outlet } from 'react-router-dom'
function Index() {
    const navigate=useNavigate()
    const [Token,setToken]=useState(null)
    useEffect(()=>{
            const token =localStorage.getItem('admintoken')
            console.log(token) 
            setToken(token)    
    },[])
  return Token !== null ? (
      <><AdminDashboard /><Outlet /></>   
  ) : (
    navigate("/login")
  );
}

export default Index