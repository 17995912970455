import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/index'
import Player from "../../components/Player/index"
function Index() {
  const [profile,setProfile]=useState({Fname:""})
  useEffect(()=>{
    const data=JSON.parse(localStorage.getItem('profile'))
    console.log(data)
    setProfile(data)
  },[])
  return (
    <div>
      <Sidebar />
      <div className="m-auto w-[30%]">
        <h1 className="font-bold text-2xl">Welcome {profile.Fname}</h1>
        <Player/>
      </div>
    </div>
  );
}

export default Index