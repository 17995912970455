/* eslint-disable no-unused-vars */
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import I1 from "../../../../images/Wavy_Bus-07.png";
import I2 from "../../../../images/image5.jpg";
import I3 from "../../../../images/Wavy_Bus-43_Single-12.png";
import I4 from "../../../../images/Wavy_Tech-09.png";
import AOS from "aos";
import I5 from "../../../../images/mdb_logo2.gif";
import I6 from "../../../../images/Node.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Index() {
  const navigator=useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const navigate=()=>{
    localStorage.setItem("courseid","html@sk")
    navigator("/dashboard/checkout")
  }
  return (
    <>
      <div className="flex  flex-col mt-12 mb-12">
        <div className="md:m-0 grid justify-items-center " data-aos="fade-down">
          <Card sx={{ maxWidth: 320, height: 450 }}>
            <CardMedia
              sx={{ height: 250, width: 320 }}
              image={I1}
              title="HTML"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                HTML
              </Typography>
              <Typography variant="body2" color="text.secondary">
                The HyperText Markup Language or HTML is the standard markup
                language for documents designed to be displayed in a web
                browser.
              </Typography>
              <CardActions>
                <del>
                  <p>Rs.300</p>
                </del>
                <p className="text-green-800 font-extrabold animate-move">
                  Now Rs.20 only
                </p>
                <Button variant="contained" onClick={e=>navigate()}>Buy</Button>
              </CardActions>
            </CardContent>
          </Card>
          <br></br>
          <Card sx={{ maxWidth: 320, height: 450 }}>
            <CardMedia
              sx={{ height: 250, width: 320 }}
              image={I2}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                CSS
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Cascading Style Sheet is Provide Design and animation process to
                the Web Page.UI is very important for the website if either
                static or dynamic.
              </Typography>
              <CardActions>
                <p className="text-orange-800 font-extrabold animate-move">
                  Coming Soon
                </p>
                <Button variant="disabled">Buy</Button>
              </CardActions>
            </CardContent>
          </Card>
          <br />
          <Card sx={{ maxWidth: 320, height: 450 }}>
            <CardMedia
              sx={{ height: 250, width: 320 }}
              image={I4}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                JAVASCRIPT
              </Typography>
              <Typography variant="body2" color="text.secondary">
                JavaScript is an the very useful Programming language for the
                Web Development. JS is Provide Dynamic changes to the HTML Page.
              </Typography>
              <CardActions>
                <p className="text-orange-800 font-extrabold animate-move">
                  Coming Soon
                </p>
                <Button variant="disabled">Buy</Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
        <div className="grid justify-items-center mt-10" data-aos="fade-right">
          <Card sx={{ maxWidth: 320, height: 450 }}>
            <CardMedia
              sx={{ height: 250, width: 320 }}
              image={I3}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                ReactJS
              </Typography>
              <Typography variant="body2" color="text.secondary">
                JavaScript Library for web Application Development.In Features
                is a Single Page Application, DOM Based Environment, Supports
                All Browsers.
              </Typography>
              <CardActions>
                <p className="text-orange-800 font-extrabold animate-move">
                  Coming Soon
                </p>
                <Button variant="disabled">Buy</Button>
              </CardActions>
            </CardContent>
          </Card>
          <br />
          <Card sx={{ maxWidth: 320, height: 450 }}>
            <CardMedia
              sx={{ height: 250, width: 320 }}
              image={I5}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                MongoDB
              </Typography>
              <Typography variant="body2" color="text.secondary">
                No-SQL Database world wide Most of the startups use MongoDB,JSON
                based data Format and available lot of Packages in the NPM.
              </Typography>
              <CardActions>
                <p className="text-orange-800 font-extrabold animate-move">
                  Coming Soon
                </p>
                <Button variant="disabled">Buy</Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
        <div className="grid justify-items-center mt-10" data-aos="fade-up">
          <Card sx={{ maxWidth: 320, height: 450 }}>
            <CardMedia
              sx={{ height: 250, width: 320 }}
              image={I6}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                NodeJS
              </Typography>
              <Typography variant="body2" color="text.secondary">
                NodeJS is a javascript run time server,that is help the
                application to high performance.
              </Typography>
              <CardActions>
                <p className="text-orange-800 font-extrabold animate-move">
                  Coming Soon
                </p>
                <Button variant="disabled">Buy</Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Index;
