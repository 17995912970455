/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Open from '../../../../images/icons8-nav-64.png'
import Close from '../../../../images/icons8-close-64.png'
function Index() {
  const [sidebar,setSidebar]=useState(true)
  const open=()=>{
  document.getElementById("nav1").hidden = false;
  document.getElementById("nav2").hidden = false;
  document.getElementById("nav3").hidden = false;
  document.getElementById("nav4").hidden = false;
  document.getElementById("nav5").hidden = false;
  document.getElementById("h1").hidden = false;
     document.getElementById("design").style.paddingLeft = "40px";
     document.getElementById("design").style.backgroundColor = "white";
     document.getElementById("h1").hidden = false;
    setSidebar(true)
  }
  const close=()=>{
    document.getElementById("nav1").hidden = true;
    document.getElementById("nav2").hidden = true;
    document.getElementById("nav3").hidden = true;
    document.getElementById("nav4").hidden = true;
    document.getElementById("nav5").hidden = true;
    document.getElementById("design").style.paddingLeft="0px"
    document.getElementById("design").style.backgroundColor="transparent"
    document.getElementById("h1").hidden = true;
    setSidebar(false)
  }
  return (
    <div>
      <div
        id="design"
        className="fixed rounded-r-3xl flex flex-col pl-10 md:pl-14 bg-white w-fit pt-16 h-full pr-16"
      >
        {sidebar ? (
          <img
            src={Close}
            className="absolute md:hidden ml-[140px] -mt-14"
            alt="icon"
            width={40}
            onClick={(e) => close()}
          ></img>
        ) : (
          <img
            src={Open}
            className="absolute md:hidden ml-[10px] -mt-14"
            alt="icon"
            width={40}
            onClick={(e) => open()}
          ></img>
        )}
        <h1
          id="h1"
          className="bg-rose-500 text-center border-2 border-slate-900 py-5 px-5 rounded-2xl"
        >
          <Link to={"/admin/dashboard"}>Dashboard</Link>
        </h1>
        <Link
          id="nav1"
          className="text-center hover:bg-red-500 rounded-3xl border-1 md:py-2 md:my-16 lg:my-8 my-3"
          to={"/admin/dashboard/course"}
        >
          Courses
        </Link>
        <Link
          id="nav2"
          className="text-center hover:bg-red-500 rounded-3xl border-1 md:py-2 md:my-16 lg:my-8 my-3"
          to={"/admin/dashboard/users"}
        >
          Users
        </Link>
        <Link
          id="nav3"
          className="text-center hover:bg-red-500 rounded-3xl border-1 md:py-2 md:my-16 lg:my-8 my-3"
          to={"/admin/dashboard/graph"}
        >
          Graph Analytics
        </Link>
        <Link
          id="nav4"
          className="text-center hover:bg-red-500 rounded-3xl border-1 md:py-2 md:my-16 lg:my-8 my-3"
          to={"/admin/dashboard/upload"}
        >
          Course Upload
        </Link>
      </div>
    </div>
  );
}

export default Index