/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import AxiosInstance  from '../../utils/AxioxInstance'
import { ToastContainer, toast } from 'react-toastify'
function index() {
  const [Data,setData]=useState()
  const Get=async()=>{
    try {
      const check=JSON.parse(localStorage.getItem('profile'))
      check?.Courses?.map(async(e)=>{
        if (e.verification){
           await AxiosInstance.get("/course/get", {
             params: { courseid: e.courseid },
           }).then((res)=>{
            setData(res.data)
           })
        }else{
          toast.info("Your not buy any Courses")
        }
         
      }
      )
     
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    Get()
  },[])
  return (
    <div>
      <ToastContainer/>
        <h1>Video Player</h1>
        {Data?.modules?.map((e)=>{
            const Video = e.split("/");
            const Name=Video[4].split('.')
            return (
              <>
                <h1 className="text-center font-extrabold text-2xl ml-20">
                  {Name[0]}
                </h1>
                <ReactPlayer
                  url={e}
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </>
            );
        })}
    </div>
  )
}

export default index