/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
const AxiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000/api"
      : "https://softwaretamilan-server.onrender.com/api",
});
AxiosInstance.interceptors.request.use(config=>{
    const token=localStorage.getItem("token")||localStorage.getItem('admintoken')
    config.headers.Authorization=`Bearer ${token}`
    return config
})
AxiosInstance.interceptors.response.use(res=>{
 return res.data?.message === "token expired"
   ? window.location.replace(
       process.env.NODE_ENV === "development"
         ? "http://localhost:3000/"
         : "https://www.softwaretamilan.online"
     )
   : res;
})
export default AxiosInstance