import React from 'react'

function index() {
  return (
    <div>
        <h1 className="text-center">Certificates</h1>
    </div>
  )
}

export default index