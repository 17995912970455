/* eslint-disable no-unused-vars */
import './App.css';
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from 'react';
import Navbar from './components/Navbar'
import Chart from './pages/admin/components/Graph/index'
import "./style/spinner.css"
import { Button } from '@mui/material';
import "react-toastify/dist/ReactToastify.css";
import Auth from './pages/admin/Auth/index'
import { Link } from 'react-router-dom';
import ClientAuth from './components/Auth/index'
import CustomerTable from './pages/admin/components/Users/index'
import Course from './pages/admin/components/Courses/index'
import Payments from "./pages/admin/components/Payments/index";
import Certificates from './components/Certificates/index'
import Profile from './components/Profile/index'
import Privacy from './components/Policy/index'
import CookieConsent, { Cookies } from "react-cookie-consent";
const Home=lazy(()=> import ("./pages/Home"))
const Courses=lazy(()=> import ('./pages/Courses/index'))
const About=lazy(()=>import ("./pages/About/index"))
const Login = lazy(()=>import("./pages/Login/index"))
const Media = lazy(()=>import("./pages/Media/index"))
const Register=lazy(()=>import("./pages/Register/index"))
const AdminLogin=lazy(()=>import("./pages/admin/Login/index"))
const Checkout=lazy(()=>import("./components/checkout/index"))
function App() {
  const CookiesFun=(e)=>{
    var note=new Notification("hi i am siva")
    var CurCookie=`Cookies=${e}; expires=Thu`
    document.cookie=CurCookie
  }
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  return (
    <div className="App" id="gradient_background">
      <CookieConsent
        location="bottom"
        buttonText="understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <Link to={"/privacy-policy"} className='text-blue-300'>Cookies Policy</Link>
      </CookieConsent>
      <Navbar />
      <Suspense
        fallback={
          <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home hello={<h1>Loaded</h1>} />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/media" element={<Media />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<Auth />}>
            <Route path="users" element={<CustomerTable />} />
            <Route path="graph" element={<Chart />} />
            <Route path="course" element={<Course />} />
            <Route path="upload" element={<Payments />} />
          </Route>
          <Route path="/dashboard" element={<ClientAuth />}>
            <Route path="payment" element={<Payments />} />
            <Route path="profile" element={<Profile />} />
            <Route path="certificates" element={<Certificates />} />
            <Route path="course" element={<Course />} />
            <Route path='checkout' element={<Checkout/>}/>
          </Route>
          <Route
            path="/*"
            element={<h1 className="text-center">Page Not Founds</h1>}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;