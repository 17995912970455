/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import I1 from "../../images/icons8-drag-list-down-50.png"
import I2 from "../../images/icons8-x-24.png"
function index() {
 const [dropdown,setDrop]=useState(false)
 const navigate=useNavigate()
 useEffect(()=>{
setDrop(false)
},[])
  return (
    <>
      {" "}
      <div className="md:hidden absolute end-5 mt-2">
        {dropdown ? (
          <img
            src={I2}
            alt="drop-on"
            className="w-[30px] h-[30px]"
            onClick={(e) => setDrop(false)}
          />
        ) : (
          <img
            src={I1}
            alt="drop-off"
            className="w-[30px] h-[30px]"
            onClick={(e) => {
              setDrop(true);
            }}
          />
        )}
      </div>
      <div className="bg-blue-400 p-3 md:flex  md:justify-between">
        <div className="">
          <h1 className="font-semibold">SOFTWARE TAMILAN OFFICIAL</h1>
        </div>
        <div className="mr-5 hidden md:inline">
          <Link
            to={"./"}
            className="mr-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
          >
            Home
          </Link>
          <Link
            to={"./courses"}
            className="mr-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
          >
            Courses
          </Link>
          <Link
            to={"./media"}
            className="mr-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
          >
            Media
          </Link>
          <Link
            to={"./about"}
            className="mr-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
          >
            About
          </Link>
          {localStorage.getItem('admintoken')||localStorage.getItem('token')?<><Link to={'/dashboard'} className='mr-5'>Dashboard</Link><button onClick={e => { localStorage.clear(); navigate('/')} }>Logout</button></>:<><Link
            to={"./login"}
            className="mr-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
          >
            Login
          </Link><Link
            to={"./register"}
            className="mr-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
          >
              Register
            </Link></>}
          
        </div>
      </div>
      <div>
        {dropdown ? (
          <div className=" flex-col bg-blue-500 text-center md:hidden">
            <Link
              to={"./"}
              onClick={(e) => {
                setDrop(false);
              }}
              className="mt-10 hover:bg-gray-500 p-2 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
            >
              Home
            </Link>
            <br />
            <Link
              onClick={(e) => {
                setDrop(false);
              }}
              to={"./courses"}
              className="mt-5 hover:bg-gray-500 p-2 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
            >
              Courses
            </Link>
            <br />
            <Link
              onClick={(e) => {
                setDrop(false);
              }}
              to={"./media"}
              className="mt-5 hover:bg-gray-500 p-2 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
            >
              Media
            </Link>
            <br />
            <Link
              onClick={(e) => {
                setDrop(false);
              }}
              to={"./about"}
              className="mt-5 hover:bg-gray-500 p-2 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
            >
              About
            </Link>
            <br />
            {localStorage.getItem("token") !== undefined ? (
              <Link
               className="mt-5 hover:bg-gray-500 p-2 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
               to={'/dashboard'}
               >
                Dashboard
              </Link>
            ) : (
              <>
                <Link
                  onClick={(e) => {
                    setDrop(false);
                  }}
                  to={"./login"}
                  className="mt-5 hover:bg-gray-500 p-2 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
                >
                  Login
                </Link>
                <br />
                <Link
                  onClick={(e) => {
                    setDrop(false);
                  }}
                  to={"./register"}
                  className="mt-5 hover:bg-gray-500 p-3 rounded hover:text-white focus:bg-slate-600 focus:text-green-200 active:bg-gray-600"
                >
                  Register
                </Link>
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default index