/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Open from "./../../images/icons8-nav-64.png";
import Close from "./../../images/icons8-close-64.png";
function index() {
  const [sidebar, setSidebar] = useState(true);
  const open = () => {
    if (document.getElementById("nav1")) {
      // document.getElementById("nav1").hidden = false;
      // document.getElementById("nav2").hidden = false;
      // document.getElementById("nav3").hidden = false;
      // document.getElementById("nav4").hidden = false;
      // document.getElementById("nav5").hidden = false;
      // document.getElementById("h1").hidden = false;
      // document.getElementById("design").style.paddingLeft = "40px";
      // document.getElementById("design").style.backgroundColor = "white";
      // document.getElementById("h1").hidden = false;
      setSidebar(true);
    } else {
      console.log("element not found");
    }
  };
  const close = () => {
    if (document.getElementById("nav1")) {
      // document.getElementById("nav1").hidden = true;
      // document.getElementById("nav2").hidden = true;
      // document.getElementById("nav3").hidden = true;
      // document.getElementById("nav4").hidden = true;
      // document.getElementById("nav5").hidden = true;
      // document.getElementById("design").style.paddingLeft = "0px";
      // document.getElementById("design").style.backgroundColor = "transparent";
      // document.getElementById("h1").hidden = true;
      setSidebar(false);
    } else {
      console.log("element not found");
    }
  };
  return (
    <div>
      <div
        id="design"
        className="fixed rounded-r-3xl flex flex-col pl-10 md:pl-14 bg-white w-fit pt-16 h-full pr-16"
      >
        {sidebar ? (
          <img
            src={Close}
            className="absolute md:hidden ml-[140px] -mt-14"
            alt="icon"
            width={40}
            onClick={(e) => close()}
          ></img>
        ) : (
          <img
            src={Open}
            className="absolute md:hidden ml-[10px] -mt-14"
            alt="icon"
            width={40}
            onClick={(e) => open()}
          ></img>
        )}
        <h1
          id="h1"
          className="bg-rose-500 text-center border-2 border-slate-900 py-5 px-5 rounded-2xl"
        >
          <Link to={"/dashboard"}>Dashboard</Link>
        </h1>
        <Link
          id="nav1"
          className="text-center hover:bg-red-500 rounded-3xl border-1 md:py-2 md:my-16 lg:my-8 my-3"
          to={"/dashboard/course"}
        >
          Courses
        </Link>
        <Link
          id="nav5"
          className="text-center hover:bg-red-500 rounded-3xl border-1 md:py-2  md:my-16 lg:my-8 my-3"
          to={"/dashboard/profile"}
        >
          Profile
        </Link>
      </div>
    </div>
  );
}

export default index;
