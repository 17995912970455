/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import Dashboard from '../../pages/Dashboard/index'
import { Outlet } from "react-router-dom";
function index() {
  const navigate = useNavigate();
  const [Token, setToken] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log(token);
    if(localStorage.getItem('email')){
      setToken(token);
    }
    
  }, []);
  return Token !== null ? (
    <>
      <Dashboard/>
      <Outlet />
    </>
  ) : (
    navigate("/login")
  );
}

export default index;
