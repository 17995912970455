import React from 'react'
import { ToastContainer } from 'react-toastify'
import Sidebar from '../components/Sidebar/index'
import UserTable from '../components/Users/index'
function Index() {
  return (
    <div>
      <ToastContainer />
      <div>
        <Sidebar />
      </div>
    </div>
  );
}

export default Index