import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

function index() {
    
ChartJS.register(ArcElement, Tooltip, Legend);
const data = {
  labels: ["HTML", "CSS", "JavaScript", "ReactJS", "NodeJS", "MERN Stack"],
  datasets: [
    {
      label: "# of Votes",
      data: [1, 0, 0, 2, 1, 1],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};
  return (
    <div className="mx-[28.1%]">
      <h1 className="text-center">Pie charts</h1>
      <Pie data={data} />
    </div>
  );
}

export default index