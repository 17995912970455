/* eslint-disable default-case */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { useEffect,useState } from 'react'
import { useRef } from 'react';
import {db,storage} from '../../firebase'
import AxiosInstance from '../../utils/AxioxInstance';
import { ToastContainer,toast } from 'react-toastify';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
function index() {
  const [picurl,setPic]=useState("")
  const [FormData,setFormData ]= useState({email:String, url: String, address: String, organization :String,type:String});
  const [image,setImage]=useState(null)
  const [updated,setUpdate]=useState(1)
  const ref=useRef()
  const ImageCheck=(e)=>{
    const email=JSON.parse(localStorage.getItem("profile"))
    setFormData({...FormData,email:email.mailid})
    if(e.target.files[0]){
      if (e.target.files[0].size > 2 * 1024 * 1024) {
        alert("Image size is too large limit is 2 MB only");
      } else {
        setImage(e.target.files[0]);
      }
    }else{
      setImage(null)
    }
  }
  const Store=async()=>{
try {
            console.log(FormData);
            await AxiosInstance.post("/profile/create", FormData).then(
              (res) => {
                console.log(res.data);
                setUpdate(Math.random())
                toast.success("Updated");
                ref.current.value = "";
              }
            );
          } catch (error) {
            toast.error("Failed");
          }

  }
  const email = JSON.parse(localStorage.getItem("profile"));
  var name = email.mailid.replace(/@.*$/, "");
  const imageRef = storageRef(storage, "images/" + name);
  const Submit=async(e)=>{
    e.preventDefault();
    if(image){
      
      const url=await uploadBytes(imageRef, image).then((snapshot) => {
        console.log(snapshot)
        });
        setFormData({ ...FormData, url: imageRef._location.path_ });
        Store();
      }
      
  }
  useEffect(()=>{
    AxiosInstance.post("/profile/get", {email:email.mailid}).then((res)=>{
      console.log(res.data)
      setFormData({...FormData, email:email.mailid, url: res.data.url, address: res.data.address, organization :res.data.organization, type:res.data.type})
    })
     getDownloadURL(imageRef)
       .then((url) => {
         setPic(url)
         // Insert url into an <img> tag to "download"
       })
       .catch((error) => {
         switch (error.code) {
           case "storage/object-not-found":
             // File doesn't exist
             toast.info("Image not uploaded");
             break;
           case "storage/unauthorized":
             // User doesn't have permission to access the object
             break;
           case "storage/canceled":
             // User canceled the upload
             break;

           // ...

           case "storage/unknown":
             // Unknown error occurred, inspect the server response
             break;
         }
       });
  }
  ,[updated]
  )
  return (
    <>
      <ToastContainer />
      <div className="m-auto w-[30%]">
        <img src={picurl} alt='Profile Photo'/>
        <h1 className="text-center font-extrabold text-[30px]">
          Profile Details
        </h1>
        <form class="w-full max-w-sm" onSubmit={(e) => Submit(e)}>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Profile Picture
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                accept="image/png,image/jpeg"
                type="file"
                ref={ref}
                onChange={(e) => ImageCheck(e)}
                required
              />
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Address
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                type="text"
                ref={ref}
                value={FormData.address}
                onChange={(e) =>
                  setFormData({ ...FormData, address: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Organization
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                type="text"
                ref={ref}
                value={FormData.organization}
                onChange={(e) =>
                  setFormData({ ...FormData, organization: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Type
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-name"
                type="text"
                value={FormData.type}
                placeholder="student or employee"
                ref={ref}
                onChange={(e) =>
                  setFormData({ ...FormData, type: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div class="md:flex md:items-center ">
            <div className="m-auto w-[30%]">
              <button
                class="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Update
              </button>
              <button
                class="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="reset"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default index